import React from 'react';
import PropTypes from 'prop-types';
import {EMAIL_MAINMENU_CLICKED, MAINMENU_CLICKED, trackEvent} from '../../../../lib/analytics';
import CategoryDropdown from './CategoryDropdown';
import DropdownArrow from '../../../../public/static/dropdown-arrow.svg';

export default function CategoryItem({label, path, ...otherProps}) {
  // send a segment event after navigating out to the category path.
  const handleClick = () => {
    trackEvent(MAINMENU_CLICKED, {label: path});
    trackEvent(EMAIL_MAINMENU_CLICKED, {label: path});
  };

  return (
    <div
      className="group relative mx-4 h-10 cursor-pointer py-1.5 text-white first:ml-8"
      data-qa={label}
    >
      <a
        href={path}
        className="flex h-full items-center justify-center whitespace-nowrap border-none text-lg text-white transition-all group-hover:text-gray-400"
        onClick={handleClick}
      >
        {label}
        <DropdownArrow className="ml-2 scale-75 invert transition-all group-hover:invert-[40%]" />
      </a>
      <CategoryDropdown item={otherProps} key={label} />
    </div>
  );
}

CategoryItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
