import React from 'react';
import PropTypes from 'prop-types';

export default function FadeBackground({isActive, onClick, backgroundColor, animation, zIndex}) {
  return (
    <div
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex="0"
      aria-label="fade-background"
      className={`${backgroundColor} ${animation} ${zIndex} ${
        isActive ? 'flex' : 'hidden'
      } fixed left-0 top-0 h-full w-full cursor-default overflow-hidden`}
    >
      &nbsp;
    </div>
  );
}

FadeBackground.defaultProps = {
  zIndex: 'z-10',
  onClick: null,
  backgroundColor: 'bg-black/50',
  animation: '',
};
FadeBackground.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  animation: PropTypes.string,
  zIndex: PropTypes.string,
};
