import React from 'react';
import PropTypes from 'prop-types';
import RightPointer from '../../../../public/static/right-pointer.svg';
import CategorySubmenuLinks from './CategorySubMenuLinks';

export default function CategorySubmenu({
  categories,
  setItemHovered,
  itemHovered,
  threeDotSubmenu,
}) {
  // HANDLERS
  const handleHover = label => {
    setItemHovered(label);
  };

  return (
    // CONTAINER
    <ul
      className={`flex min-w-[12.25rem] cursor-pointer list-none flex-col bg-gray-800 p-0 text-sm ${
        threeDotSubmenu ? 'rounded-l-none' : 'rounded-l-xl'
      }`}
      data-qa="dropdown"
    >
      {categories.map(({id, label, subCategories}) => (
        // ITEM
        <>
          <li
            key={id}
            className={`group flex h-[3.25rem] items-center justify-between whitespace-nowrap px-7 ${
              label === itemHovered && 'bg-gray-700'
            } ${
              threeDotSubmenu
                ? 'first:rounded-tl-none last:rounded-bl-none'
                : 'first:rounded-tl-xl last:rounded-bl-xl'
            }`}
            onMouseEnter={() => handleHover(label)}
            data-qa={label}
          >
            {label}
            <RightPointer />
          </li>
          <CategorySubmenuLinks
            subCategories={subCategories}
            threeDotSubmenuLinks={!!threeDotSubmenu}
            isSelected={label === itemHovered}
          />
        </>
      ))}
    </ul>
  );
}

CategorySubmenu.defaultProps = {
  categories: [],
  threeDotSubmenu: false,
};

CategorySubmenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      subCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
  setItemHovered: PropTypes.func.isRequired,
  itemHovered: PropTypes.string.isRequired,
  threeDotSubmenu: PropTypes.bool,
};
