import React from 'react';
import PropTypes from 'prop-types';
import {EMAIL_MOBILEMENU_CLICKED, MOBILEMENU_CLICKED, trackEvent} from '../../../../lib/analytics';

export default function CategorySubMenuDropdown({label, id, subcategories, isActive, onToggle}) {
  const handleClick = (e, path) => {
    e.preventDefault();
    trackEvent(MOBILEMENU_CLICKED, {label: path});
    trackEvent(EMAIL_MOBILEMENU_CLICKED, {label: path});
    window.location.href = path;
  };

  return (
    <li className="h-auto w-full list-none" key={id}>
      <button
        className="flex w-full cursor-pointer items-center justify-between whitespace-nowrap py-2.5 pl-9 pr-7 text-base capitalize"
        onClick={() => onToggle(label)}
        type="button"
      >
        <span>{label}</span>
        <span
          className={`h-2 w-3 bg-[url('/static/dropdown-arrow.svg')] bg-contain bg-center bg-no-repeat transition-all ${
            isActive && 'rotate-180'
          }`}
        />
      </button>
      <ul
        className={`ml-9 h-auto list-none overflow-x-hidden border-l border-gray-100 p-0 transition-all ${
          isActive
            ? 'max-h-full translate-y-0 overflow-y-auto opacity-100'
            : 'max-h-0 -translate-y-4 opacity-0'
        }`}
      >
        {subcategories.map(item => (
          <li className="flex list-none p-0 py-3 text-sm" key={item.id}>
            <a
              onClick={e => handleClick(e, item.path)}
              className="w-full px-4"
              aria-label={`sub-item-link-${label}`}
              href={item.path}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
}

CategorySubMenuDropdown.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};

CategorySubMenuDropdown.defaultProps = {
  label: '',
  id: 0,
  isActive: false,
  onToggle: () => {},
  subcategories: [],
};
