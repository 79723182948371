import React, {useState, useContext} from 'react';
import NavContext from '../../data/NavContext';
import CategoryItem from './CategoryItem';

export default function CategoryMenu() {
  const {menuList} = useContext(NavContext);
  const [categorySelected, setCategorySelected] = useState(null);

  const onMenuClick = label => {
    setCategorySelected(label);
  };

  const onBackClick = () => {
    setCategorySelected(null);
  };

  return (
    <ul
      className={`relative h-full list-none flex-col border-t border-gray-100 p-0 transition-all ${
        categorySelected ? '-left-[100%]' : 'left-0'
      }`}
    >
      {menuList.map(category => {
        if (!category?.id) return null;
        return (
          <CategoryItem
            key={category.id}
            id={category.id}
            label={category.label}
            path={category.path}
            categories={category.categories}
            onMenuClick={onMenuClick}
            onBackClick={onBackClick}
            categorySelected={categorySelected}
          />
        );
      })}
    </ul>
  );
}
