import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IcViewAll as ViewAll} from '../../../UI/Atoms/Icons';
import SubMenuDropdown from './CategorySubMenuDropdown';
import {EMAIL_MAINMENU_CLICKED, MAINMENU_CLICKED, trackEvent} from '../../../../lib/analytics';

export default function CategorySubMenu({isSelected, label, path, categories, onBack}) {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const onDropdownToggle = dropdown => {
    if (dropdown === activeDropdown) {
      setActiveDropdown(null);
      return;
    }
    setActiveDropdown(dropdown);
  };

  const handleClick = e => {
    e.preventDefault();
    trackEvent(MAINMENU_CLICKED, {label: path});
    trackEvent(EMAIL_MAINMENU_CLICKED, {label: path});
    window.location.href = path;
  };

  return (
    <ul
      className={`absolute top-0 z-50 h-[calc(100vh-64px)] w-full list-none overflow-y-auto overflow-x-hidden border-t border-gray-100 p-0 ${
        isSelected ? 'left-[100%]' : 'left-[200%]'
      }`}
      role="menu"
      aria-label="submenu-category"
    >
      <li className="sticky top-0 z-50 flex w-full list-none items-center bg-gray-100">
        <button
          type="button"
          onClick={onBack}
          className="flex items-center bg-gray-100 text-lg font-semibold capitalize"
        >
          <span className="my-5 ml-5 inline-block h-3 w-2 rotate-180 bg-[url('/static/right-pointer.svg')] bg-contain bg-center bg-no-repeat invert saturate-0 sepia-0" />
          <span className="ml-2 pr-2">{label}</span>
        </button>
        <button
          type="button"
          className="flex h-8 w-8 items-center justify-center rounded-full bg-white"
          onClick={e => handleClick(e)}
        >
          <a href={path}>
            <ViewAll className="h-6 w-6" />
          </a>
        </button>
      </li>
      {categories.map(subCategory => (
        <SubMenuDropdown
          key={subCategory.id}
          id={subCategory.id}
          label={subCategory.label}
          subcategories={subCategory.subCategories}
          isActive={activeDropdown === subCategory.label}
          onToggle={onDropdownToggle}
        />
      ))}
    </ul>
  );
}

CategorySubMenu.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      subCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
  onBack: PropTypes.func,
  isSelected: PropTypes.bool,
};

CategorySubMenu.defaultProps = {
  isSelected: false,
  categories: [],
  onBack: () => {},
  path: '',
  label: '',
};
