import React from 'react';
import PropTypes from 'prop-types';
import CategorySubMenu from './CategorySubMenu';

export default function CategoryItem({
  id,
  label,
  path,
  categories,
  onMenuClick,
  onBackClick,
  categorySelected,
}) {
  return (
    <li className="list-none" key={id}>
      <button
        type="button"
        key={`submenu-button-${label}`}
        aria-label={`item-mobile-${label}`}
        className="flex w-full items-center justify-between px-7 py-3 text-lg capitalize text-gray-800"
        onClick={() => onMenuClick(label)}
      >
        <span className="flex-grow text-left" key={`submenu-label-${label}`}>
          {label}
        </span>

        <span
          key={`submenu-arrow-${label}`}
          className={`pointer-events-none inline-block h-3 w-2 bg-[url('/static/right-pointer.svg')] bg-contain bg-center bg-no-repeat invert saturate-0 sepia-0 ${
            categorySelected ? 'hidden' : ''
          }`}
        />
      </button>
      <CategorySubMenu
        key={`submenu-${label}`}
        label={label}
        path={path}
        categories={categories}
        isSelected={categorySelected === label}
        onBack={onBackClick}
      />
    </li>
  );
}

CategoryItem.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  path: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onMenuClick: PropTypes.func,
  onBackClick: PropTypes.func,
  categorySelected: PropTypes.string,
};

CategoryItem.defaultProps = {
  id: null,
  label: '',
  path: '',
  categories: [],
  onMenuClick: () => {},
  onBackClick: () => {},
  categorySelected: null,
};
