import React, {useState} from 'react';
import PropTypes from 'prop-types';

import CategorySubmenu from './CategorySubmenu';

export default function CategoryDropdown({item: {categories}, threeDotMenu}) {
  const firstSubItem = categories[0];
  const [itemHovered, setItemHovered] = useState(firstSubItem.label);

  return (
    <div
      className={`absolute -z-10 mt-1 hidden transition-all group-hover:flex ${
        threeDotMenu ? 'left-full top-0' : ''
      }`}
      onMouseLeave={() => setItemHovered(categories[0].label)}
    >
      <CategorySubmenu
        {...{categories, setItemHovered, itemHovered}}
        threeDotSubmenu={!!threeDotMenu}
      />
    </div>
  );
}

CategoryDropdown.propTypes = {
  item: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        subCategories: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  threeDotMenu: PropTypes.bool,
};

CategoryDropdown.defaultProps = {
  threeDotMenu: false,
};
