import React from 'react';
import PropTypes from 'prop-types';
import {EMAIL_SUBMENU_CLICKED, SUBMENU_CLICKED, trackEvent} from '../../../../lib/analytics';
import CategoryBadge from './CategoryBadge';
import useCampaignContext from '../../../../context/CampaignContext';

export default function CategorySubMenuLink({subCategories, threeDotSubmenuLinks, isSelected}) {
  const {getBadge} = useCampaignContext();
  const handleClick = path => {
    trackEvent(SUBMENU_CLICKED, {label: path});
    trackEvent(EMAIL_SUBMENU_CLICKED, {label: path});
  };

  return (
    <div
      className={`absolute left-full flex-wrap rounded-r-xl bg-gray-700 ${
        isSelected ? 'flex' : 'hidden'
      } ${
        threeDotSubmenuLinks
          ? 'writing-mode-horizontal-tb min-h-full content-start'
          : 'writing-mode-vertical-lr h-full'
      }`}
      data-qa="submenu"
    >
      {subCategories.map(({label, path, id}) => (
        <a
          key={id}
          href={path}
          className="writing-mode-horizontal-tb flex h-[3.125rem] min-w-[12rem] items-center justify-between whitespace-nowrap px-8 text-sm transition-all hover:font-bold"
          data-qa={label}
          onClick={() => handleClick(path)}
        >
          {label}
          <CategoryBadge badge={getBadge(path)} />
        </a>
      ))}
    </div>
  );
}

CategorySubMenuLink.defaultProps = {
  subCategories: [],
  threeDotSubmenuLinks: false,
  isSelected: false,
};

CategorySubMenuLink.propTypes = {
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  threeDotSubmenuLinks: PropTypes.bool,
  isSelected: PropTypes.bool,
};
